"use client";

import Menu from "./menu";
import NavBarHeader from "./nevBarHeader";

const Navbar = () => {
  return (
    <div 
      className="
        sticky top-0 z-30 
        2xl:h-100px 
        xl:h-90px 
        h-[60px]
        bg-white 
        transition-[height] duration-sticky ease-out
      "
    >
      <nav 
        className="block absolute w-full z-[100] bg-white"
      >
        <div 
          className="
            block relative 
            w-full 
            px-10px mx-auto
            2xl:max-w-2xl lg:max-w-lg  md:max-w-md  sm:max-w-sm 
          "
        >
          <NavBarHeader/>
          <Menu/>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
