"use client";

import React, { useState, useEffect } from "react";
import { usePathname } from 'next/navigation';  
import DropDown from "../dropDown";
import Company from "./company";
import Solution from "./solution";
import Product from "./product";
import Image from "next/image";
import { langStore } from "@/stores";

const Menu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const pathname = usePathname();
    const { code, setCode } = langStore();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleLanguage = () => {
        setCode(code === 'ko' ? 'en' : 'ko');
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        if (!window.googleTranslateElementInit) {
          window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
              {
                pageLanguage: 'ko',
                includedLanguages: 'en,ko,ja'
              },
              'google_translate_element'
            );
          };
        
          const script = document.createElement('script');
          script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
          script.async = true;
          document.head.appendChild(script);
        }
      }, []);
    
        useEffect(() => {
          const selectField = document.querySelector('.goog-te-combo') as HTMLSelectElement;
          if (selectField) {
            if (code === 'en') {
                console.log("번역합니다")
                selectField.value = code;
                selectField.dispatchEvent(new Event('change'));
            } else {
              console.log("돌아옵니다.")
              const path = '/';
              document.cookie = `googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=ibznts.com; path=${path};`;
              document.cookie = `googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
              window.location.reload();
            }
          }
        }, [code]);



    useEffect(() => {
        setIsMenuOpen(false);
    }, [pathname]);

    return (
        <div className="relative">
            <div id="google_translate_element" style={{ marginBottom: '10px', display: 'none' }}></div>
            {/* 모바일 메뉴 버튼 */}
            {isMobile && (
                <button
                    className="p-2 bg-gray-200 text-center z-20 fixed top-2 left-2 m-2" // Adjusted the `top` property to move it slightly down
                    onClick={toggleMenu}
                >
                    <i className={`fa ${isMenuOpen ? 'fa-times' : 'fa-bars'}`} style={{ fontSize: '24px' }} />
                </button>
            )}

            {/* 모바일 로고 */}
            {isMobile && (
                <div className="fixed top-0 left-0 right-0 flex items-center justify-center bg-white shadow-md py-2 z-10">
                    <a href="/">
                        <Image 
                            src="/contents/logo.png"
                            alt="Logo"
                            width={169}
                            height={60}
                        />
                    </a>
                </div>
            )}

            {/* PC 메뉴 */}
            {!isMobile && (
                <div className="hidden xl:flex xl:items-center xl:justify-between">
                    <div className="flex items-center">
                        <a href="/" className="mr-4">
                            <Image 
                                src="/contents/logo.png"
                                alt="Logo"
                                width={169}
                                height={60}
                            />
                        </a>
                        <ul className="flex list-none space-x-4">
                            <DropDown className="title" title="HOME">/</DropDown>
                            <Company />
                            <Solution />
                            <Product />
                            <DropDown className="title" title="CONTACT US">/contactUs</DropDown>
                        </ul>
                    </div>
                    <div className="flex items-center text-right font-2xl">
                        <i className="fa fa-phone mr-2" style={{ fontSize: '28px' }}></i>

                        <div className="border-r border-gray-300 h-6 mx-2"></div>

                        <div>
                            <div>Call us today!</div>
                            <div>+82 51-781-7507</div>
                        </div>
                    </div>
                </div>
            )}

            {/* 모바일 드롭다운 메뉴 */}
            {isMobile && (
                <ul
                    className={`${
                        isMenuOpen ? 'fixed top-16 left-0 w-full bg-white shadow-lg' : 'hidden'
                    } list-none z-10 max-h-[350px] px-4 py-2 overflow-y-auto transition-transform duration-300 ease-in-out`}
                >
                    <DropDown className="title" title="HOME">/</DropDown>
                    <Company />
                    <Solution />
                    <Product />
                    <DropDown className="title" title="CONTACT US">/contactUs</DropDown>
                    <div className="fa-tel-phon flex items-center justify-end mt-4">
                        <div className="border-r border-gray-300 h-6 mx-2"></div>
                        <div>
                            <div>Call us today!</div>
                            <div>+82 51-781-7507</div>
                        </div>
                    </div>
                </ul>
            )}

            {/* 언어 토글 버튼 */}
            <div className="fixed bottom-5 right-5">
                <button
                    onClick={toggleLanguage}
                    className={`relative px-6 py-2 bg-blue-500 text-white rounded-full shadow-lg flex items-center space-x-2 transition-colors duration-300 ease-in-out ${code === 'ko' ? 'bg-blue-500' : 'bg-gray-300'}`}
                >
                    <i className="fa fa-globe text-xl"></i>
                    <span className="text-sm font-medium">{code}</span>
                    <span className={`block w-4 h-4 rounded-full bg-white transition-transform duration-300 ease-in-out ${code === 'ko' ? 'translate-x-0' : 'translate-x-5'}`}></span>
                </button>
            </div>
        </div>
    );
};

export default Menu;
