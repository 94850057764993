import exp from "constants"
import DropDown from "../dropDown"

const Company = () => {
    return (
        <DropDown 
        className="title"
        title="회사소개"
        >
            <DropDown 
            className="subTitle"
            title="대표이사 인사말">
                /company/message
            </DropDown>
            <DropDown 
            className="subTitle"
            title="핵심 가치">
                /company/value
            </DropDown>
            <DropDown 
            className="subTitle"
            title="회사 연혁">
                /company/history
            </DropDown>
            <DropDown 
            className="subTitle"
            title="사업 실적">
                /company/performance
            </DropDown>
            <DropDown 
            className="subTitle"
            title="보유 권리">
                /company/right
            </DropDown>
            <DropDown 
            className="subTitle"
            title="조직도">
                /company/organization
            </DropDown>
            <DropDown 
            className="subTitle"
            title="Coporation Identity">
                /company/identity
            </DropDown>
        </DropDown>
    )
}

export default Company