"use client";

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/navigation';

import { useTitleStore } from '../../stores'

interface DropDownProps {
    children: React.ReactNode;
    className: string;
    title: string;
}

const DropDown = ({ children, className, title }: DropDownProps) => {
    const router = useRouter();
    // const { selectedTitle, setSelectedTitle } = useSelectedTitle();
    const { setSelectedTitle } = useTitleStore();
    const [isHover, setIsHover] = useState<boolean>(false);
    const [isClick, setIsClick] = useState<boolean>(false);
    const isString = typeof children === 'string';
    console.log(title)
    console.log(setSelectedTitle)
    // useEffect(() => {
    //     if (title === selectedTitle) {
    //         setIsClick(true);
    //     } else {
    //         setIsClick(false);
    //     }
    // }, [selectedTitle, title]);


    const liClasses = classNames(
        "relative float-left",
        "transition-transform duration-dropDown ease-dropDown",
        {
            "block max-xl:float-none": className === 'title',
            "w-full text-sm list-none max-xl:float-none": className === 'subTitle',
        }
    );

    const aClasses = classNames(
        "relative block whitespace-nowrap float-left",
        "h-full", 
        "text-13px font-inter uppercase font-semibold tracking-norma flex mt-2",
        "cursor-pointer",
        "max-xl:float-none max-xl:w-full max-xl:border-t-0 max-xl:border-b-[1px] max-xl:border-[#e0e0e0] max-xl:-mb-px max-xl:py-[13px] max-xl:px-0",
        {
            "xl:py-35px xl:px-12px 2xl:py-40px 2xl:px-15px": className === 'title',
            "max-xl-dropDown-after-hover": !isString && isClick,
            "max-xl-dropDown-after": !isString && !isClick,
            "xl-dropDown-after-hover": !isString && isHover && className === 'title',
            "xl-dropDown-after": !isString && !isHover && className === 'title',
            "xl:py-15px xl:px-12px xl:border-b-px xl:border-[#f5f5f5]": className === 'subTitle',
            "xl-dropDownMenu-after-hover": !isString && isHover && className === 'subTitle',
            "xl-dropDownMenu-after": !isString && !isHover && className === 'subTitle',
            "text-[#086AD8]": title === "HOME"
        }
    );

    const ulClasses = classNames(
        "absolute min-w-40 text-sm text-left z-40 float-left animate-inPlace",
        "xl:bg-none xl:bg-repeat xl:p-0 xl:w-250px xl:bg-white",
        "max-xl:w-full max-xl:relative max-xl:float-none max-xl:border-none max-xl:pl-15px max-xl:py-0 ",
        {
            "xl:block": isHover,
            "xl:hidden": !isHover,
            "max-xl:block": isClick,
            "max-xl:hidden": !isClick,
            "top-full": className === 'title',
            "xl:left-full": className === 'subTitle',
            "max-xl:top-full": className === 'subTitle'
        }
    );

    const handleClick = () => {
        if (isString) {
            router.push(children as string);
        } else {
            setIsClick(!isClick);
        }
    };

    return (
        <li 
            className={liClasses}
            onMouseLeave={() => { setIsHover(false); }}
        >
            <a 
                title={title}
                className={aClasses}
                onClick={handleClick}
                onMouseEnter={() => { setIsHover(true); }}
            >
                {title}
            </a>

            {!isString && (
                <ul className={ulClasses}>
                    {React.Children.map(children, (child) => 
                        React.cloneElement(child as React.ReactElement<any>, {
                            onClick: () => {
                                setSelectedTitle(`${title} > ${React.isValidElement(child) ? child.props.title : ''}`);
                            }
                        })
                    )}
                </ul>
            )}
        </li>
    );
};

export default DropDown;
