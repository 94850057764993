"use client";

const Header: React.FC = () => {
  return (
    <div className="py-4 px-4 bg-[#086AD8] text-[#f8f9fa] border-b border-opacity-30 border-white">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* 왼쪽 섹션 */}
          <div className="flex-1 text-center md:text-left mb-4 md:mb-0 hidden md:flex">
            <ul className="flex flex-col md:flex-row justify-center md:justify-start items-center space-y-2 md:space-y-0 md:space-x-8">
              <li className="flex items-center text-sm">
                <i className="fas fa-map-marker-alt mr-2 text-base"></i>
                <a href="#" className="text-sm">Rep. of KOREA</a>
              </li>
              <li className="flex items-center text-sm md:ml-8">
                <a href="mailto:b816f5dc.ibznts.com@kr.teams.ms" className="flex items-center font-semibold">
                  <i className="fas fa-envelope-open mr-2"></i>
                  james.park@ibznts.com
                </a>
              </li>
            </ul>
          </div>
          {/* 오른쪽 섹션 */}
          <div className="flex-1 text-center md:text-right">
            <ul className="flex justify-center md:justify-end items-center">
              <li className="flex items-center text-sm">
                <i className="fas fa-clock mr-2"></i>
                <span>Office Hours: 9:00 AM – 18:00 PM</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
