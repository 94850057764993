"use client";

import Image from "next/image";

const NavBarHeader = () => {
    return (
        <div className="
            block float-left h-auto
            max-xl:float-none max-xl:block max-xl:px-0 max-xl:text-center max-xl:h-[60px]
            max-xl:pt-0 max-xl:mt-0 max-xl:overflow-hidden
        ">
            <button className="relative z-10 ">
                <i />
            </button>
            <a className="
                inline-block float-left whitespace-nowrap h-auto mr-4 bg-transparent
                transition-[height] duration-dropDown ease-dropDown 
                2xl:py-20px
                xl:py-15px xl:px-15px xl:mt-0
                lg:-ml-15px
                navBarHeaderLogo
            ">
                
            </a>
        </div>
    );
}

export default NavBarHeader;
