import {create} from 'zustand'
import { devtools } from 'zustand/middleware';

interface LangStore{
    code: string;
    setCode: (codeL:string) => void;
}

const langStore = create<LangStore>() (
    devtools(
        (set) => ({
            code: "ko",
            setCode(code: string) {set({code: code})}
        }),
        {name: "LangStore"}
    )
)

export { langStore }