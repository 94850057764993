"use client";

import {create} from 'zustand';
import { devtools } from 'zustand/middleware';

interface TitleState {
    selectedTitle: string;
    setSelectedTitle: (title: string) => void;
}

const useTitleStore = create<TitleState>() (
    devtools(
        (set)=> ({
        selectedTitle: '',
        setSelectedTitle: (title: string) => set({ selectedTitle: title }),
        }),
    {name: "useTitleStore"}
    )
);

export {useTitleStore};