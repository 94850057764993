import DropDown from "../dropDown";

const Solution = () => {
    return (
        <DropDown 
            className="title"
            title="솔루션"
        >
            <DropDown 
                className="subTitle"
                title="UTM"
            >
                <DropDown
                    className="subTitle"
                    title="화상감시시스템"
                >
                    /solution/utm/video
                </DropDown>
                <DropDown
                    className="subTitle"
                    title="울타리침입시스템"
                >
                    /solution/utm/fence
                </DropDown>
                <DropDown
                    className="subTitle"
                    title="화재감시시스템"
                >
                    /solution/utm/fire
                </DropDown>
                <DropDown
                    className="subTitle"
                    title="환경감시시스템"
                >
                    /solution/utm/environment
                </DropDown>
            </DropDown>
            <DropDown 
                className="subTitle"
                title="ITS"
            >
                <DropDown
                    className="subTitle"
                    title="터널유고시스템"
                >
                    /solution/its/turnnel
                </DropDown>
                <DropDown
                    className="subTitle"
                    title="도로감시시스템"
                >
                    /solution/its/road
                </DropDown>
                <DropDown
                    className="subTitle"
                    title="무선중계통합시스템"
                >
                    /solution/its/wireLess
                </DropDown>
            </DropDown>
            <DropDown 
                className="subTitle"
                title="LMS"
            >
                /solution/lms
            </DropDown>
        </DropDown>
    );
};

export default Solution;
