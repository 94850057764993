import { create } from 'zustand';

interface SecurityItem {
    id: string;
    src: string;
    alt: string;
}

interface ProductStore {
    securityItems: SecurityItem[];
    setSecurityItems: (products: SecurityItem[]) => void;
    getSecurityItemById: (id: string, product: SecurityItem) => void;
}

const initDisateItem = [
    { id: '1', src: '/contents/image/security/Access-Control-Unit.png', alt: 'Access Control Unit' },
    { id: '2', src: '/contents/image/security/Alarm-Interface-Unit.png', alt: 'Alarm Interface Unit' },
    { id: '3', src: '/contents/image/security/Card-Reader.png', alt: 'Card Reader' },
    { id: '4', src: '/contents/image/security/DEAD-BLOT-LOCK.png', alt: 'DEAD BLOT LOCK' },
    { id: '5', src: '/contents/image/security/EM-LOCK.png', alt: 'EM LOCK' },
    { id: '6', src: '/contents/image/security/HICS-SYSTEM.png', alt: 'HICS SYSTEM' },
    { id: '7', src: '/contents/image/security/HICS-모듈-CM.png', alt: 'HICS 모듈 (CM)' },
    { id: '8', src: '/contents/image/security/HICS-모듈CPU.png', alt: 'HICS 모듈 (CPU)' },
    { id: '9', src: '/contents/image/security/HICS-모듈-EM.png', alt: 'HICS 모듈 (EM)' },
    { id: '10', src: '/contents/image/security/HICS-모듈HICS-SELF.png', alt: 'HICS 모듈 (HICS SELF)' },
    { id: '11', src: '/contents/image/security/HICS-모듈-PM.png', alt: 'HICS 모듈 (PM)' },
    { id: '12', src: '/contents/image/security/Network-Interface-Module-NIM.png', alt: 'Network Interface Module (NIM)' },
    { id: '13', src: '/contents/image/security/NPC알람DATA.png', alt: 'NPC(알람DATA)' },
    { id: '14', src: '/contents/image/security/Sensor-Cable-MC-115.png', alt: 'Sensor Cable (MC-115)' },
    { id: '15', src: '/contents/image/security/Termination-Unit-TU.png', alt: 'Termination Unit (TU)' },
    { id: '16', src: '/contents/image/security/IC-Card.png', alt: '버튼' },
    { id: '17', src: '/contents/image/security/순찰관리서버.png', alt: '순찰관리서버' },
    { id: '18', src: '/contents/image/security/신호분배기.png', alt: '신호분배기' },
    { id: '19', src: '/contents/image/security/오디오제어-모듈.png', alt: '오디오제어 모듈' },
    { id: '20', src: '/contents/image/security/조명제어-모듈.png', alt: '조명제어 모듈' },
    { id: '21', src: '/contents/image/security/터치스위치.png', alt: '터치스위치' },
    { id: '22', src: '/contents/image/security/투광등제어기.png', alt: '투광등제어기' }
];

const SecurityStore = create<ProductStore>(set => ({
    securityItems: initDisateItem,
    setSecurityItems(securityItems: SecurityItem[]) {set({securityItems : securityItems})},
    getSecurityItemById(id: string, product: SecurityItem) {set((state) => {
        const newSecurityItems = state.securityItems.map(item => {
            if (item.id === id) {
                return product
            }
            return item
        })
        return {securityItems : newSecurityItems}
    })}
}));

export {SecurityStore};
