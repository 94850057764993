import DropDown from "../dropDown";

const Product = () => {
    return (
        <DropDown
        className="title"
        title="제품소개"
        >
            <DropDown
            className="subTitle"
            title="UTM">
                <DropDown
                className="subTitle"
                title="보안/방범">
                    /product/utm/security
                </DropDown>
                <DropDown
                className="subTitle"
                title="재난 방지">
                    /product/utm/disaste
                </DropDown>
            </DropDown>
        </DropDown>
    )
}

export default Product