import { create } from 'zustand';

interface DisateItem {
    id: string;
    src: string;
    alt: string;
}

interface ProductStore {
    disateItems: DisateItem[];
    setDisateItems: (products: DisateItem[]) => void;
    getDisateItemById: (id: string, product: DisateItem) => void;
}

const initDisateItem = [
    { id: '1', src: '/contents/image/disaste/HICS-모듈-DIR.png', alt: 'HICS 모듈 (DIR)' },
    { id: '2', src: '/contents/image/disaste/HICS-모듈-DIV.png', alt: 'HICS 모듈 (DIV)' },
    { id: '3', src: '/contents/image/disaste/HICS-모듈-DO.png', alt: 'HICS 모듈 (DO)' },
    { id: '4', src: '/contents/image/disaste/HICS-모듈-WM.png', alt: 'HICS 모듈 (WM)' },
    { id: '5', src: '/contents/image/disaste/Local-Controller-Date-Acquisition-L.D.A.png', alt: 'Local Controller & Date Acquisition (L.D.A)' },
    { id: '6', src: '/contents/image/disaste/지능형-영상처리기HVP-642.png', alt: '지능형 영상처리기(HVP-642)' },
    { id: '7', src: '/contents/image/disaste/지능형-영상처리기HVP-HD648.png', alt: '지능형 영상처리기(HVP-HD648)' },
    { id: '8', src: '/contents/image/disaste/지능형-영상처리서버.png', alt: '지능형 영상처리서버' },
    { id: '9', src: '/contents/image/disaste/집합형-SELF.png', alt: '집합형 SELF' },
    { id: '10', src: '/contents/image/disaste/화재감시-제어콘솔.png', alt: '화재감시 제어콘솔' },
];

const disasteStore = create<ProductStore>(set => ({
    disateItems: initDisateItem,
    setDisateItems(disateItems: DisateItem[]) {set({disateItems : disateItems})},
    getDisateItemById(id: string, product: DisateItem) {set((state) => {
        const newDisateItems = state.disateItems.map(item => {
            if (item.id === id) {
                return product
            }
            return item
        })
        return {disateItems : newDisateItems}
    })}
}));

export {disasteStore};
