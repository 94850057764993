"use client";

import {create} from 'zustand';
import { devtools } from 'zustand/middleware';

interface SidebarStore {
    selectedItem: string;
    setSelectedItem: (title: string) => void;
}

const sidebarStore = create<SidebarStore>() (
    devtools(
        (set)=> ({
            selectedItem: '',
            setSelectedItem: (selectedItem: string) => set({ selectedItem: selectedItem }),
        }),
    {name: "sidebarStore"}
    )
);

export {sidebarStore};